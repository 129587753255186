<template>
  <div>
    <h1>Privacy Notice</h1>
    <p>Last Modified: 2021-02-28</p>
    <h2>Summary</h2>
    <h3>Cookies</h3>
    <p>jonathansmovies.com uses cookies exclusively for login purposes.</p>
    <h3>Other Services</h3>
    <p>
      You may optionally choose to use Facebook to login, in doing so you
      provide us with the following information: your name on Facebook, your
      profile picture, and your unique account identification number. We store
      no other information from Facebook.
    </p>

    <h3>Personally Identifiable Information</h3>
    <p>
      We store your account information (username and password) as well as a
      record of any nominations and votes you may make. These are used for the
      main purpose of the application as well to recommend you films you may
      enjoy.
    </p>
    <p>
      Our data is stored encrypted at rest in a secure database. Controls are
      placed on our data to prevent it from being obtained by third parties.
    </p>

    <h3>Third Parties</h3>
    <p>
      We do not sell your information or engage with any third parties for the
      purposes of tracking or advertisting. We do share some limited information
      with <a href="https://sentry.io/privacy/">Sentry.io</a> for the purposes
      of diagnosing failures in our application.
    </p>

    <h3>Opting-Out and Other Queries</h3>
    <p>
      If you have any questions or queries about your data and/or wish for it to
      be deleted or to see a copy of what is retained please email
      info@jonathansmovies.com
    </p>

    <h2>Long Lawyer Version</h2>
    <strong>Privacy Policy</strong>
    <p>
      Arran France built the Jonathan's Movies app as a Free app. This SERVICE
      is provided by Arran France at no cost and is intended for use as is.
    </p>
    <p>
      This page is used to inform visitors regarding my policies with the
      collection, use, and disclosure of Personal Information if anyone decided
      to use my Service.
    </p>
    <p>
      If you choose to use my Service, then you agree to the collection and use
      of information in relation to this policy. The Personal Information that I
      collect is used for providing and improving the Service. I will not use or
      share your information with anyone except as described in this Privacy
      Policy.
    </p>
    <p><strong>Information Collection and Use</strong></p>
    <p>
      For a better experience, while using our Service, I may require you to
      provide us with certain personally identifiable information, including but
      not limited to first name, last name, and email address.
    </p>
    <div>
      <p>
        The app does use third party services that may collect information used
        to identify you.
      </p>
      <p>
        Link to privacy policy of third party service providers used by the app
      </p>
      <ul>
        <!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!---->
        <li>
          <a
            href="https://sentry.io/privacy/"
            target="_blank"
            rel="noopener noreferrer"
            >Sentry</a
          >
        </li>
        <!----><!----><!----><!----><!----><!----><!----><!----><!---->
      </ul>
    </div>
    <p><strong>Log Data</strong></p>
    <p>
      I want to inform you that whenever you use my Service, in a case of an
      error in the app I collect data and information (through third party
      products) on your phone called Log Data. This Log Data may include
      information such as your device Internet Protocol (“IP”) address, device
      name, operating system version, the configuration of the app when
      utilizing my Service, the time and date of your use of the Service, and
      other statistics.
    </p>
    <p><strong>Cookies</strong></p>
    <p>
      Cookies are files with a small amount of data that are commonly used as
      anonymous unique identifiers. These are sent to your browser from the
      websites that you visit and are stored on your device's internal memory.
    </p>
    <p>
      This Service does use these “cookies” explicitly for the purposes of
      keeping you logged in to our service.
    </p>
    <p><strong>Service Providers</strong></p>
    <p>
      I may employ third-party companies and individuals due to the following
      reasons:
    </p>
    <ul>
      <li>To facilitate our Service;</li>
      <li>To provide the Service on our behalf;</li>
      <li>To perform Service-related services; or</li>
      <li>To assist us in analyzing how our Service is used.</li>
    </ul>
    <p>
      I want to inform users of this Service that these third parties have
      access to your Personal Information. The reason is to perform the tasks
      assigned to them on our behalf. However, they are obligated not to
      disclose or use the information for any other purpose.
    </p>
    <p><strong>Security</strong></p>
    <p>
      I value your trust in providing us your Personal Information, thus we are
      striving to use commercially acceptable means of protecting it. But
      remember that no method of transmission over the internet, or method of
      electronic storage is 100% secure and reliable, and I cannot guarantee its
      absolute security.
    </p>
    <p><strong>Links to Other Sites</strong></p>
    <p>
      This Service may contain links to other sites. If you click on a
      third-party link, you will be directed to that site. Note that these
      external sites are not operated by me. Therefore, I strongly advise you to
      review the Privacy Policy of these websites. I have no control over and
      assume no responsibility for the content, privacy policies, or practices
      of any third-party sites or services.
    </p>
    <p><strong>Children’s Privacy</strong></p>
    <p>
      These Services do not address anyone under the age of 13. I do not
      knowingly collect personally identifiable information from children under
      13 years of age. In the case I discover that a child under 13 has provided
      me with personal information, I immediately delete this from our servers.
      If you are a parent or guardian and you are aware that your child has
      provided us with personal information, please contact me so that I will be
      able to do necessary actions.
    </p>
    <p><strong>Changes to This Privacy Policy</strong></p>
    <p>
      I may update our Privacy Policy from time to time. Thus, you are advised
      to review this page periodically for any changes. I will notify you of any
      changes by posting the new Privacy Policy on this page.
    </p>
    <p>This policy is effective as of 2021-02-28</p>
    <p><strong>Contact Us</strong></p>
    <p>
      If you have any questions or suggestions about my Privacy Policy, do not
      hesitate to contact me at info@jonathansmovies.com.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
